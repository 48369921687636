import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import request from '../core/request';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const { isFrontpage } = props;

    const $bar = $el.find('[data-menu-bar]');
    const $menuToggle = $('body').find('[data-menu-toggle]');
    const $menu = $el.find('[data-menu]');
    const $menuContent = $el.find('[data-menu] > *');
    const $desktopMenu = $el.find('[data-desktop-menu]');
    
    let menuOpen = false;
    let searchOpen = false;
    let focusedElement = null;
    let breakpoint = Viewport.breakpoint.name;

    let isHidden = false;
    let isSearching = false;
    let hiddenThreshold;
    let currentScrollTop = Viewport.scrollTop;
    
    const closeMenu = (tween = true) => {

        if (!menuOpen) {
            return;
        }

        $menuToggle.attr('aria-expanded', 'false');
        menuOpen = false;

        if (tween) {
            gsap.to($menuContent.nodes, { opacity: 0, duration: 0.1, ease: 'linear' });
            gsap.to($menu.nodes, {
                height: '0%',
                duration: 0.3,
                ease: 'sine.out',
                onComplete: () => {
                    $menu.attr('hidden', true);
                }
            });
        } else {
            $menu.attr('hidden', true);
        }

        $el.removeClass('js-menu-open');
    };
    
    const openMenu = (tween = true) => {
        console.log('openMenu');

        if (menuOpen) {
            return;
        }
        
        menuOpen = true;
        $menu.attr('hidden', null);
        $menuToggle.attr('aria-expanded', 'true');

        if (tween) {
            gsap.set($menu.nodes, { height: '0%' });
            gsap.set($menuContent.nodes, { opacity: 0 });
            gsap.to($menu.nodes, { height: '100%', duration: 0.8, ease: 'quint.out' });
            gsap.to($menuContent.nodes, { opacity: 1, duration: 0.6, delay: 0.2, ease: 'sine.out' });
        }

        $el.addClass('js-menu-open');
    };
    
    const toggleMenu = () => {
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };
    
    const hide = () => {
        if (isHidden) {
            return;
        }
        isHidden = true;
        $bar.addClass('is-hidden');
        $menu.addClass('is-hidden');
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        $bar.removeClass('is-hidden');
        $menu.removeClass('is-hidden');
    };
    
    const parseData = data => {
        const $data = $(data);
        
        $searchResults.empty().append($data.find('[data-result-list]')).attr('hidden', null);
        $searchResultStatus.empty().append($data.find('[data-result-status]')).attr('hidden', null);
        
        // $spinner.addClass('hidden');
        // Components.init($result);
        
        // gsap.to($content.nodes, { duration: 0.2, opacity: 1 });
    };
    
    const submitSearch = () => {
        if (isSearching) {
            return;
        }
        
        request
            .get(`${searchUrl}`)
            .query({ q: $searchForm.find('input').val() })
            .then(res => {
                if (res.statusCode === 200) {
                    parseData(res.text);
                } else {
                    // todo : feilmelding
                }
            });
    };
    
    const onBreakpoint = () => {
        /*
        const wasSmall = isSmall(breakpoint);
        if (wasSmall !== isSmall()) {
            closeMenu(false);
        }
        
         */
        breakpoint = Viewport.breakpoint.name;
    };

    const onBodyKeyUp = e => {
        if (!menuOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            closeMenu();
        }
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - currentScrollTop) < 5) {
            return;
        }
        if (scrollTop < hiddenThreshold) {
            if (isFrontpage) {
                hide();
            } else {
                show();
            }
        } else {
            const direction = scrollTop > currentScrollTop ? 'down' : 'up';
            if (direction === 'down') {
                hide();
            } else {
                show();
            }
        }
        currentScrollTop = scrollTop;
    };

    const onInnerFocus = e => {
        show();
    };

    const onResize = () => {
        hiddenThreshold = isFrontpage ? Viewport.height : 80;

        if (!isHidden) {
            return;
        }

        requestAnimationFrame(() => {
            const { scrollTop } = Viewport;
            if (scrollTop <= hiddenThreshold) {
                if (isFrontpage) {
                    hide()
                } else {
                    show();
                }
            }
        });
    };

    const init = () => {
        const navId = $menuToggle.attr('href');

        $menuToggle
            .attr({
                tabIndex: '0',
                role: 'button',
                'aria-expanded': 'false'
            })
            .on('keydown', e => {
                const key = e.key || e.keyCode || e.which || null;
                if (['Enter', 13].indexOf(key) > -1) {
                    e.preventDefault();
                    toggleMenu();
                }
            })
            .on('click', e => {
                e.preventDefault();
                toggleMenu();
            })
            .get(0)
            .removeAttribute('href');
        
        $('body').on('keyup', onBodyKeyUp);

        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);

        $el.on('focusin', 'a,button', onInnerFocus);

        onResize();

        // Account for the menu being opened already before the JS had the chance to boot
        requestAnimationFrame(() => {
            if (navId && window.location.hash === navId) {
                openMenu(false);
                window.location.hash = '';
                if (window.history && window.history.replaceState) {
                    window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
                }
            }
        });
    };

    const destroy = () => {
        $menuToggle.off('click keydown');
        $('body').off('keyup', onBodyKeyUp);
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
        $el.off('click focusin');
    };

    return {
        init,
        destroy
    };
};
