import $ from '../core/Dom';
import gsap from 'gsap';


export default (el, props) => {
    const $el = $(el);

    const init = () => {
        $el.on('click', 'button', e => {
            const $button = $(e.triggerTarget);
            const $content = $button.parent().next();
            const expanded = $button.attr('aria-expanded') === 'true' || false

            $button.attr('aria-expanded', !expanded);

            if (expanded) {
                gsap.to($content.get(0), {
                    duration: 0.6, height: 0, ease: 'quint.inOut', onComplete: () => {
                        $content.attr('hidden', '');
                    }
                });
            } else {
                $content.attr('hidden', null);

                gsap.set($content.get(0), { height: 0, overflow: 'hidden' });
                gsap.to($content.get(0), { duration: 0.6, height: 'auto', ease: 'quint.inOut' });
            }
        });
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };
};
