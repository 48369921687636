import $ from '../core/Dom';
import gsap from 'gsap';


export default (el, props) => {
    const $el = $(el);

    let observer = null;

    const init = () => {
        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const { isIntersecting, intersectionRatio } = entry;
                if (!isIntersecting || intersectionRatio < 0.15) {
                    return;
                }
                onIntersect();
            });
        }, {
            threshold: [0.15]
        });

        observer.observe(el);
    };

    const destroy = () => {
        killObserver();
    };
    
    const onIntersect = () => {
        killObserver();
        
        $el.find('strong').addClass('is-visible');
    };

    const killObserver = () => {
        if (observer) {
            observer.unobserve($el.get(0));
            observer = null;
        }
    };

    return {
        init,
        destroy
    };
};
