import $ from '../core/Dom';
//import mapboxgl from 'mapbox-gl';
const loadMapbox = require('bundle-loader?lazy&name=[name]!mapbox-gl');

import gsap from 'gsap';

export default (el, props) => {

    const $el = $(el);

    const markers = props.markers;
    const apiKey = props.apiKey;
    const centerLatitude = parseFloat(props.centerLat);
    const centerLongitude = parseFloat(props.centerLng);

    const markerIcons = {
        default: '<div style="width: 24px; height: 24px; cursor: pointer;" class=""><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" fill="#F7CC53"/><line x1="12" y1="6" x2="12" y2="18" stroke="#303030" stroke-width="2"/><line x1="18" y1="12" x2="6" y2="12" stroke="#303030" stroke-width="2"/></svg></div>'
    }

    const init = () => {
        loadMapbox(mapboxgl => {
            mapboxgl.accessToken = apiKey;

            const map = new mapboxgl.Map({
                container: el,
                scrollZoom: false,
                style: 'mapbox://styles/nyekjeller/ckxel8h830nh615p38v4kyeu5',
                center: [centerLongitude, centerLatitude],
                zoom: 14,
                attributionControl: false
            }).addControl(new mapboxgl.AttributionControl({
                compact: true
            }));

            let nav = new mapboxgl.NavigationControl();
            map.addControl(nav, 'top-right');

            markers.forEach((marker) => {
                var marker = new mapboxgl.Marker({
                    element: $(markerIcons.default).get(0)
                })
                    .setLngLat([marker.location.lng, marker.location.lat])
                    .setPopup(new mapboxgl.Popup().setHTML('<div class="text-blue-dark">' + marker.popupContent + '</div>'))
                    .addTo(map);
            });

        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
