import $ from '../core/Dom';

const VIDEO_TIMEOUT = 1500;

export default (el, { sources }) => {

    const $el = $(el);
    const $video = $el.find('[data-video]');
    const videoElement = $video.get(0);
    const $image = $el.find('[data-image]');

    let timer;

    const clearTimer = () => {
        if (!timer) {
            return;
        }
        clearTimeout(timer);
        timer = null;
    };

    const cantPlay = () => {
        $video.attr('hidden', true);
        clearTimer();
        $image.addClass('lazyload');
    };

    if (!videoElement || !videoElement.canPlayType || !videoElement.canPlayType('video/mp4')) {
        cantPlay();
        return;
    }

    const setTimer = () => {
        clearTimer();
        timer = setTimeout(cantPlay, VIDEO_TIMEOUT);
    };

    const onTimeUpdate = e => {
        if (!timer) {
            return;
        }
        const { currentTime } = e.target;
        if (!!currentTime && currentTime > 0.001) {
            clearTimer();
        }
    };

    const onLoadStart = e => {
        if (!timer) {
            return;
        }
        setTimer();
    };

    videoElement.addEventListener('loadstart', onLoadStart);
    videoElement.addEventListener('loadedmetadata', onLoadStart);
    videoElement.addEventListener('loadeddata', onLoadStart);
    videoElement.addEventListener('canplay', onLoadStart);
    videoElement.addEventListener('timeupdate', onTimeUpdate);

    const playAndCatch = () => {
        try {
            const promise = videoElement.play();
            if (promise !== undefined) {
                promise.then(() => {
                    clearTimer();
                }).catch(e => {
                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                        cantPlay();
                    }
                });
            }
        } catch (error) {
            console.error(error);
            cantPlay();
        }
    };

    const swapSource = ({ src }) => {
        if ($video.attr('src') === src) {
            return;
        }
        $video.attr('src', src);
        playAndCatch();
    };

    if (sources.length > 1) {
        sources.forEach(source => {
            const mq = window.matchMedia(source.media);
            const onChange = e => {
                if (e.matches) {
                    swapSource(source);
                }
            };
            try {
                mq.addEventListener('change', onChange);
            } catch (error) {
                mq.addListener(onChange);
            }
            // Sets initial breakpoint
            if (mq.matches) {
                swapSource(source);
            }
        });

    } else {
        swapSource(sources[0]);
    }

    setTimer();
};
