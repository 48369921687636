import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import request from '../core/request';

import gsap from 'gsap';

export default (el, props) => {

    const $el = $(el);
    const $mapWrap = $el.find('[data-map-wrap]');
    const $mapWrapInner = $el.find('[data-total-map-wrapper]');
    const $list = $el.find('[data-map-list]');

    const { mapEndpoint, pois } = props;

    let $map = null;
    let activeModalId = null;
    let observer = null;

    const activateModal = id => {
        if (activeModalId === null) {
            const $modal = $el.find('#' + id + '-modal')
            $modal.css({ display: 'flex' });
            gsap.set($modal.get(0), { opacity: 0 });
            gsap.to($modal.get(0), { duration: 0.2, opacity: 1 });

            activeModalId = id;
        }
    };

    const deactivateModal = id => {
        if (activeModalId !== null) {
            const $modal = $el.find('#' + id + '-modal')
            gsap.to($modal.get(0), {
                duration: 0.2, opacity: 0, onComplete: () => {
                    $modal.css({ display: 'none' });

                }
            });

            activeModalId = null;
        }
    };

    const activateListItem = id => {
        $list.find('a[data-element-id="' + id + '"]').addClass('active');
        $map.find('#' + id + '_hover').addClass('active');
    };

    const deactivateListItem = id => {
        $list.find('a[data-element-id="' + id + '"]').removeClass('active');
        $map.find('#' + id + '_hover').removeClass('active');
    };

    const activateIllustrationElement = id => {
        $map.find('#' + id + '_hover').addClass('active');
    };

    const deactivateIllustrationElement = id => {
        $map.find('#' + id + '_hover').removeClass('active');
    };


    const elementMouseEnter = id => {
        activateListItem(id);
    };

    const elementMouseLeave = id => {
        deactivateListItem(id);
    };

    const elementClick = id => {
        activateModal(id)
    };

    const listMouseEnter = id => {
        activateIllustrationElement(id);
    };

    const listMouseLeave = id => {
        deactivateIllustrationElement(id);
    };

    const listClick = id => {
        activateModal(id)
    };

    const onIntersect = () => {
        killObserver();

        if (!mapEndpoint || mapEndpoint === '') {
            return;
        }

        request
            .get(mapEndpoint)
            .then(res => {
                const { status, text } = res || {};
                if (status === 200 && text) {
                    // Do something
                    $mapWrapInner.css({ opacity: 0 });
                    gsap.set($mapWrapInner.get(0), { opacity: 0 });
                    $mapWrapInner.append($(text));
                    gsap.to($mapWrapInner.get(0), { duration: 0.6, opacity: 1 });
                    setupMapListeners();
                } else {
                    // Do something else
                    console.error(res.statusText);
                }
            })
            .catch(error => {
                console.error(error);
            });


    };

    const killObserver = () => {
        if (observer) {
            observer.unobserve($el.get(0));
            observer = null;
        }
    };

    const setupMapListeners = () => {
        $map = $el.find('[data-map]');

        pois.forEach(id => {
            $map.find('#' + id + '_hover').css({ cursor: 'pointer' });

            $map.find('#' + id + '_hover').on('mouseenter', e => {
                elementMouseEnter(id);
            });
            $map.find('#' + id + '_hover').on('mouseleave', e => {
                elementMouseLeave(id);
            });
            $map.find('#' + id + '_hover').on('click', e => {
                elementClick(id);
            });
        });
    };

    const onBreakpoint = e => {
        if (e.detail.current.size >= 1200) {
            $el.find('[data-map-type-toggle]').removeClass('bg-beige');
            $el.find('[data-map-type-toggle="map"]').addClass('bg-beige');
            $mapWrap.css({ display: '' });
            $list.css({ display: '' });
        }
    };

    const init = () => {
        pois.forEach(id => {
            $list.find('a').on('mouseenter', e => {
                listMouseEnter($(e.triggerTarget).data('element-id'));
            });
            $list.find('a').on('mouseleave', e => {
                listMouseLeave($(e.triggerTarget).data('element-id'));
            });
            $list.find('a').on('click', e => {
                e.preventDefault();
                listClick($(e.triggerTarget).data('element-id'));
            });
        });

        $el.on('click', '[data-modal-close]', e => {
            if (activeModalId !== null) {
                deactivateModal(activeModalId);
            }
        });

        $el.on('click', '[data-map-type-toggle]', e => {
            e.preventDefault();
            const $trigger = $(e.triggerTarget);
            const activateType = $trigger.data('map-type-toggle');
            $el.find('[data-map-type-toggle]').removeClass('bg-beige');
            $trigger.addClass('bg-beige');

            if (activateType === 'map') {
                $mapWrap.css({ display: 'block' });
                $list.css({ display: 'none' });
            } else if (activateType === 'list') {
                $mapWrap.css({ display: 'none' });
                $list.css({ display: 'block' });
            }
        });

        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const { isIntersecting, intersectionRatio } = entry;
                if (!isIntersecting || intersectionRatio < 0.15) {
                    return;
                }
                onIntersect();
            });
        }, {
            threshold: [0.15]
        });

        observer.observe(el);

        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        pois.forEach(id => {
            if ($map) {
                $map.find('#' + id + '_hover').off('mouseenter');
                $map.find('#' + id + '_hover').off('mouseleave');
                $map.find('#' + id + '_hover').off('click');
            }

            $list.find('a').off('mouseenter');
            $list.find('a').off('mouseleave');
            $list.find('a').off('click');
        });

        killObserver();
    };

    return {
        init,
        destroy
    };
};
